<i18n lang="json5">{
	ru: {
		edit: 'Редактирование',
		close: 'Закрыть',
		user_name: 'Ваше имя',
		phone_number: 'Номер телефона',
		phone_number2: 'Номер телефона 2',
		phone_invalid: 'Укажите номер в формате +99890 000 00 00',
		save: 'Сохранить',
		edit_success: 'Данные успешно отредактированы',
	},
	uz: {
		edit: 'Tahrirlash',
		close: 'Yopish',
		user_name: 'Ismingiz',
		phone_number: 'Telefon raqami',
		phone_number2: 'Telefon raqami 2',
		phone_invalid: 'Telefon raqamini +99890 000 00 00 formatida kiriting',
		save: 'Saqlash',
		edit_success: 'Maʼlumotlar muvaffaqiyatli tahrirlandi',
	}
}</i18n>

<template>
	<portal to="modal5">
		<modal
			name="userEditModal"
			:adaptive="true"
			:focusTrap="true"
			:minWidth="280"
			:maxWidth="640"
			:height="'auto'"
			:scrollable="true"
			@before-open="beforeOpen"
		>
			<div class="p-4" v-if="user">
				<div class="p-4 rounded bg-white">
					<h3 class="mb-6 text-2xl font-bold">
						{{ $t('edit') }}
					</h3>
					<form @submit.prevent="onSubmit" novalidate>
						<div class="mb-4">
							<label class="block">
								{{ $t('user_name') }}<span class="text-my-red">*</span>
								<input
									class="block rounded border mt-1 w-full lg:py-3"
									:class="$v.form.name.$error && submitted ? 'border-my-red' : 'border-gray-200'"
									type="text"
									v-model.trim="$v.form.name.$model"
								/>
							</label>
						</div>
						<div class="mb-4">
							<label class="block">
								{{ $t('phone_number') }}<span class="text-my-red">*</span>
								<input
									class="block rounded border mt-1 w-full lg:py-3"
									:class="$v.form.tel1.$error && submitted ? 'border-my-red' : 'border-gray-200'"
									type="text"
									v-model.trim="$v.form.tel1.$model"
								/>
							</label>
							<span v-if="$v.form.tel1.required && !$v.form.tel1.valid && submitted" class="block text-my-red text-sm">
								{{ $t('phone_invalid') }}
							</span>
						</div>
						<div class="mb-4">
							<label class="block">
								{{ $t('phone_number2') }}
								<input
									class="block rounded border mt-1 w-full lg:py-3"
									:class="$v.form.tel2.$error && submitted ? 'border-my-red' : 'border-gray-200'"
									type="text"
									v-model.trim="$v.form.tel2.$model"
								/>
							</label>
							<span v-if="!$v.form.tel2.valid && submitted" class="block text-my-red text-sm">
								{{ $t('phone_invalid') }}
							</span>
						</div>
						<button
							type="submit"
							:disabled="isSubmitting"
							class="btn buy-button px-8 py-2 h-12 bg-gradient-to-b from-my-blue-from to-my-blue-to text-white"
							:class="{loading: isSubmitting}"
						>
							{{ $t('save') }}
						</button>
					</form>
				</div>
				<div
					class="absolute right-0 top-0 w-8 h-8 p-2 bg-my-blue-gray rounded-full cursor-pointer"
					@click="$modal.hide('userEditModal')"
				>
					<img
						src="/design/close-blue.svg"
						class="block w-4 h-4"
						alt=""
					/>
				</div>
			</div>
		</modal>
	</portal>
</template>

<script>
import { maxLength, required } from "vuelidate/lib/validators";

export default {
	name: 'UserEditModal',
	data() {
		return {
			form: {
				name: null,
				tel1: null,
				tel2: null,
			},
			submitted: false,
			isSubmitting: false,
		}
	},
	validations() {
		const telRegex = /^\+\s?[0-9\s()-]{9,}$/
		const telValidator = tel => {
			if (!telRegex.test(tel)) {
				return false
			}

			const telNumbers = tel.replace(/\D/g, '')
			const isUzbTel = telNumbers.startsWith('998')

			return isUzbTel ? telNumbers.length === 12 : true;
		}
		return {
			form: {
				name: {
					required,
					maxLength: maxLength(100),
				},
				tel1: {
					required,
					valid: value => telValidator(value)
				},
				tel2: {
					valid: value => !value || telValidator(value)
				},
			},
		}
	},
	methods: {
		beforeOpen() {
			this.form.name = this.user?.name || null
			this.form.tel1 = this.user?.tel || null
			this.form.tel2 = this.user?.tel2 || null
		},
		onSubmit() {
			this.submitted = true
			this.$v.form.$touch()

			if (!this.$v.form.$invalid) {
				this.updateUser()
			}
		},
		async updateUser() {
			try {
				this.isSubmitting = true
				await this.$store.dispatch('user/updateUserData', {
					name: this.form.name,
					tel1: this.form.tel1,
					tel2: this.form.tel2 || null,
				})
				this.isSubmitting = false
				this.$toast.success(this.$t('edit_success'))
				this.$modal.hide('userEditModal')
			} catch (e) {
				this.isSubmitting = false
				if (!this.$axios.isAxiosError(e)) {
					this.$sentry?.captureException(e)
				}
			}
		}
	},
	computed: {
		user() {
			return this.$store.getters['user/user']
		},
	},
}
</script>
